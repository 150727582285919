import React from "react";

import { Text, Button, Container, ButtonAlt } from "@atoms";
import brand from "@utils/brand";
import classNames from "classnames";

const PreDonate = ({ heading, links, copy, button, altLink }) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center py-12 lg:py-24",
        { "bg-white": brand.lgca }
      )}
    >
      <Container>
        <Text
          variant="h3"
          className={classNames("text-center", {
            "text-orange": brand.lgca,
          })}
        >
          {heading}
        </Text>
        {links && (
          <div className="mx-auto mt-10 mb-6 w-full max-w-lg">
            <div className="-mx-2 flex flex-wrap">
              {!!links?.length &&
                links.map(link => (
                  <div key={link.link} className="mb-4 w-full px-2 sm:w-1/2">
                    <Button to={link.link} fullWidth>
                      {link.text}
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        )}
      </Container>
      <Container variant="xs">
        <div className="my-4 mx-auto max-w-xl">
          <Text
            variant="body--large-tight"
            className="rich-text bold-red text-center"
          >
            {copy}
          </Text>
        </div>
        {button && button.link && (
          <div className="mx-auto my-6 w-full max-w-lg">
            <div className="-mx-2 flex flex-wrap items-center justify-center">
              <Button size="sm" to={button.link}>
                {button.text}
              </Button>
            </div>
          </div>
        )}
        {altLink && altLink.link && (
          <div className="mx-auto my-6 w-full max-w-lg">
            <div className="flex flex-wrap items-center justify-center">
              <ButtonAlt to={altLink.link}>{altLink.text}</ButtonAlt>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

PreDonate.defaultProps = {};

export default PreDonate;
